import React from 'react';
import { motion } from 'framer-motion';

const Modal = ({ selectedImg, setSelectedImg }) => {

    const handleClick = (e) =>{
        if(e.target.classList.contains('backdrop')) {
            setSelectedImg(null);
        }
    }

    return(
        <motion.div className="backdrop" onClick={ handleClick } 
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        >
            <motion.div className="pic-holder"
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
            >
                <img src={ selectedImg.url } alt="enlarged pic"/>
                <div className="description">
                    { selectedImg.origFileName && 'File name: ' + selectedImg.origFileName + ' ' }
                    { selectedImg.createdAt && 'Uploaded: ' + selectedImg.createdAt }
                </div>
            </motion.div>
        </motion.div>
    )
}

export default Modal;