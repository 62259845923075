import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyDVf1eBKMjKnzDwA3s0SENrzE4LiHtbN-A",
    authDomain: "firegram-73907.firebaseapp.com",
    databaseURL: "https://firegram-73907.firebaseio.com",
    projectId: "firegram-73907",
    storageBucket: "firegram-73907.appspot.com",
    messagingSenderId: "511721637247",
    appId: "1:511721637247:web:dc759953c8e0864d7f464a"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp }